import { mergeProps } from '@/utils/merge';
import { StandaloneRow } from 'base/components/Row';

export const MabraStandaloneRow: typeof StandaloneRow = (props) => {
  const { className } = props.options ?? {};

  const hasBackground = className?.split(' ').includes('bg');

  return <StandaloneRow {...mergeProps({ options: { size: hasBackground ? 'secondary' : 'primary' } }, props)} />;
};
