import { tw } from '@/utils/tw';
import BaseRowTheme from 'base/components/Row/theme';

const { Button } = BaseRowTheme;

const Row = tw.theme({
  extend: BaseRowTheme,
  slots: {
    base: [
      'bg-no-repeat',
      'data-[row-type=courseFilter]:bg-yellow-sea-100',
      "data-[row-type=courseFilter]:bg-[url('/image/course/course-filter-blob.svg')]",
    ],
    headline: ['-mb-4 group-[.courses]/front:-mb-1', 'group-[.courses]/front:font-bold sm:text-headline-sm'],
    footer: ['[&:not(:first-child)]:mt-5.5'],
  },
  variants: {
    size: {
      primary: {
        base: [
          'data-[row-type=courseFilter]:left-1/2',
          'data-[row-type=courseFilter]:w-screen',
          'data-[row-type=courseFilter]:max-w-none',
          'data-[row-type=courseFilter]:-translate-x-1/2',
          'data-[row-type=courseFilter]:py-8',
          'md:gap-y-6',
        ],
        footer: [
          'group-[.courses]/front:[&:not(:first-child)]:mb-2',
          'group-[.courses]/front:[&:not(:first-child)]:mt-0',
          'sm:group-[.courses]/front:[&:not(:first-child)]:mt-1.5',
        ],
      },
      secondary: {
        base: [
          'group-[.courses]/front:has-[article]:pb-8',
          'group-[.courses]/front:has-[article]:pt-6',
          'group-[.courses]/front:has-[article]:last:mb-12',
          'md:gap-y-6',
        ],
        footer: ['group-[.courses]/front:[&:not(:first-child)]:mt-0'],
      },
    },
  },
  defaultVariants: {
    size: 'primary',
  },
});

export default Object.assign(Row, { Button });
