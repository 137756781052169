const cn = require('classnames');

const $image = {
  image_size_header: cn('w-10', 'h-10', 'md:w-16', 'md:h-16'),
  image_size_teaser: cn(
    'w-10',
    'h-10',
    'md:w-16',
    'md:h-16',
    'lg:grid-cols-4:w-10',
    'lg:grid-cols-4:h-10',
    'md:grid-cols-4:w-10',
    'md:grid-cols-4:h-10',
    'md:grid-cols-11:w-16',
    'md:grid-cols-11:h-16',
    'lg:grid-cols-8:w-16',
    'lg:grid-cols-8:h-16',
  ),
  image_size_bodytext: cn('w-10', 'w-10'),

  image_variant_header: cn(''),
  image_variant_teaser: cn(
    'hidden',
    'xs:grid-cols-7:inline',
    'sm:grid-cols-7:inline',
    'md:grid-cols-6:inline',
    'lg:grid-cols-4:inline',
  ),
  image_variant_bodytext: cn(''),
};

const $name = {
  name_size_default: cn(
    'text-headline-2xs',
    'md:text-headline-xs',
    'pl-0.5',
    'md:grid-cols-4:pl-0',
    'lg:grid-cols-4:pl-0.5',
    'py-1',
    'w-fit',
    'after:h-full',
    'after:w-8',
    'before:h-full',
    'before:w-8',
  ),

  name_variant_header: cn(
    'hidden',
    'md:inline',
    'relative',
    'after:block',
    'after:absolute',
    'after:top-0',
    'after:-right-2',
    'after:-z-1',
    'after:-skew-x-[20deg]',
    'before:block',
    'before:absolute',
    'before:top-0',
    'before:-left-8',
    'before:-z-1',
  ),
  name_variant_teaser: cn(
    'hidden',
    'md:inline',
    'relative',
    'after:block',
    'after:absolute',
    'after:top-0',
    'after:-right-4',
    'after:-z-1',
    'after:-skew-x-[20deg]',
    'before:block',
    'before:absolute',
    'before:top-0',
    'before:-left-4',
    'before:-z-1',
    'before:-skew-x-[20deg]',
    'grid-cols-4:hidden',
    'md:grid-cols-4:hidden',
    'md:grid-cols-11:inline',
    'lg:grid-cols-8:inline',
  ),
  name_variant_bodytext: cn('hidden'),

  name_colors_first: cn('bg-cherry-blossom-200', 'after:bg-cherry-blossom-200', 'before:bg-cherry-blossom-200'),
  name_colors_second: cn('bg-yellow-sea-500', 'after:bg-yellow-sea-500', 'before:bg-yellow-sea-500'),
  name_colors_third: cn('bg-elm-100', 'after:bg-elm-100', 'before:bg-elm-100'),
  name_colors_fourth: cn('bg-eastern-blue-400', 'after:bg-eastern-blue-400', 'before:bg-eastern-blue-400'),
  name_colors_fifth: cn('bg-orpiment-300', 'after:bg-orpiment-300', 'before:bg-orpiment-300'),
};

const $title = {
  title_size_default: cn(
    'text-headline-2xs',
    'md:text-headline-xs',
    'pl-0.5',
    'md:grid-cols-4:pl-0',
    'md:grid-cols-6:pl-0.5',
    'lg:grid-cols-4:pl-0.5',
    'py-1',
    'after:h-full',
    'after:w-8',
    'before:h-full',
    'before:w-8',
  ),

  title_variant_header: cn(
    'relative',
    'after:block',
    'after:absolute',
    'after:top-0',
    'after:-right-1.5',
    'after:-z-1',
    'after:-skew-x-[20deg]',
    'before:block',
    'before:absolute',
    'before:top-0',
    'before:-left-8',
    'before:-z-1',
    'group-data-[image=false]:before:-skew-x-[20deg]',
    'group-data-[image=false]:before:-left-1.5',
  ),
  title_variant_teaser: cn(
    'relative',
    'after:block',
    'after:absolute',
    'after:top-0',
    'after:-right-[7%]',
    'after:-z-1',
    'after:-skew-x-[20deg]',
    'before:block',
    'before:absolute',
    'before:top-0',
    'before:-z-1',
    'before:-skew-x-[20deg]',
    'before:-left-[7%]',
    'group-data-[image=false]:before:-skew-x-[20deg]',
    'group-data-[image=false]:before:-left-1.5',
    'sm:grid-cols-4:before:-skew-x-[20deg]',
    'sm:grid-cols-4:before:-left-[7%]',
    'md:grid-cols-11:before:skew-x-0',
    'md:grid-cols-11:before:-left-8',
    'lg:grid-cols-8:before:skew-x-0',
    'lg:grid-cols-8:before:-left-8',
    'lg:grid-cols-4:before:skew-x-0',
    'lg:grid-cols-4:before:-left-5',
  ),
  title_variant_bodytext: cn(
    'relative',
    'after:block',
    'after:absolute',
    'after:top-0',
    'after:-right-1.5',
    'after:-z-1',
    'after:-skew-x-[20deg]',
    'before:block',
    'before:absolute',
    'before:top-0',
    'before:-left-8',
    'before:-z-1',
    'group-data-[image=false]:before:-skew-x-[20deg]',
    'group-data-[image=false]:before:-left-1.5',
  ),

  title_colors_first: cn('bg-cherry-blossom-300', 'after:bg-cherry-blossom-300', 'before:bg-cherry-blossom-300'),
  title_colors_second: cn('bg-yellow-sea-300', 'after:bg-yellow-sea-300', 'before:bg-yellow-sea-300'),
  title_colors_third: cn('bg-elm-200', 'after:bg-elm-200', 'before:bg-elm-200'),
  title_colors_fourth: cn('bg-eastern-blue-200', 'after:bg-eastern-blue-200', 'before:bg-eastern-blue-200'),
  title_colors_fifth: cn('bg-orpiment-200', 'after:bg-orpiment-200', 'before:bg-orpiment-200'),
};

module.exports = {
  ...$image,
  ...$name,
  ...$title,

  size_header: cn('-ml-1', 'md:ml-3', '-mt-10', 'md:-mt-15', 'data-[image=false]:-mt-9', 'data-[image=false]:ml-6'),
  size_teaser: cn(
    'ml-2',
    '-mt-4',
    'sm:-ml-2',
    'md:ml-2',
    'md:-mt-15',
    'xs:grid-cols-7:ml-0',
    'sm:grid-cols-4:ml-2',
    'sm:grid-cols-4:-mt-5',
    'sm:grid-cols-8:-ml-2',
    'md:grid-cols-4:ml-3',
    'md:grid-cols-4:-mt-5',
    'md:grid-cols-8:ml-3',
    'md:grid-cols-11:ml-2',
    'md:grid-cols-11:-mt-15',
    'lg:grid-cols-4:-ml-2',
    'lg:grid-cols-8:ml-2',
    'lg:grid-cols-8:-mt-15',
    'data-[image=false]:ml-3',
    'data-[image=false]:-mt-4',
  ),
  size_bodytext: cn('-ml-2', '-mt-3'),

  variant_default: cn('flex', 'relative', 'z-1', 'group', 'not-prose'),
};
